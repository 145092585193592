import { ReactNode } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Chip, Link, Stack, Tooltip, Typography } from '@mui/material'
import { GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { LocationPaths } from 'location.types'
import { get, startCase } from 'lodash'
import { Link as ReactRouterLink } from 'react-router-dom'
import { theme } from 'theme'
import { PAID_ADD_ON, PAID_ADD_ON_COMPLIMENTARY } from 'utility/coachEndDateDescriptor'
import { isDate } from 'utility/dateParsers'
import { MMM_DD_YYYY } from 'utility/timeFormats'
import { CoachAssignmentState } from 'types'
import { formatDate } from './formatters'

export const renderAsChip = (params: GridRenderCellParams<any, any, any>) => {
  if (!params.value) return null

  return (
    <Tooltip title={params.formattedValue}>
      <Chip variant="outlined" label={params.formattedValue} />
    </Tooltip>
  )
}

export const renderAsChipWithTooltip = (tooltip: string) => {
  return function renderCell(params: GridRenderCellParams<any, any, any>) {
    if (!params.value) return null

    return (
      <Tooltip title={tooltip}>
        <Chip variant="outlined" label={params.formattedValue} />
      </Tooltip>
    )
  }
}

export const renderAsCheck = (params: GridRenderCellParams<any, any, any>) => {
  if (typeof params.value !== 'boolean') return null
  if (params.value) return <CheckIcon sx={{ color: theme.palette['brand-primary'].main }} />

  return <CloseIcon sx={{ color: theme.palette['danger'].main }} />
}

export const renderCoachEndDate = (tooltipLookup?: {}) => {
  return function render(params: GridRenderCellParams<string, Date>) {
    if (!params.value) return null

    const color = [PAID_ADD_ON, PAID_ADD_ON_COMPLIMENTARY].includes(params.value)
      ? 'primary'
      : undefined
    const chip = (
      <Chip
        variant={color ? undefined : 'outlined'}
        color={color}
        label={
          isDate(params.value)
            ? formatDate(params as GridValueFormatterParams<string | Date>)
            : params.value
        }
      />
    )
    if (!tooltipLookup) return chip
    return (
      <Tooltip title={tooltipLookup[params.value as keyof typeof tooltipLookup]}>{chip}</Tooltip>
    )
  }
}

export const renderAsTextWithTooltip = (
  { value }: GridRenderCellParams<any, any, any>,
  startCaseValue: boolean = false
) => {
  if (!value) return <Typography> - </Typography>

  const formattedValue = startCaseValue ? startCase(value) : value

  return (
    <Tooltip title={formattedValue}>
      <span>{formattedValue}</span>
    </Tooltip>
  )
}

export const renderAsTextWithTooltipPrepend = (tooltipPrepend: string) => {
  return function renderCell({ value }: GridRenderCellParams<any, any, any>) {
    if (!value) return null

    return (
      <Tooltip title={`${tooltipPrepend}: ${value}`}>
        <span>{value}</span>
      </Tooltip>
    )
  }
}

export const renderAsPossiblyEmptyField = (
  params: GridRenderCellParams<any, any, any>,
  formatter: ((rawString: string) => string) | null = startCase
) => {
  if (!params.value) return <Typography> - </Typography>

  return params.formattedValue || formatter?.(params.value) || params.value
}

export const renderAsExternalLink = (text: string) => {
  return function render(params: GridRenderCellParams<string, Date>) {
    if (!params.value) return null

    return (
      <Link
        href={params.value}
        style={{ color: theme.palette['brand-primary'].dark }}
        target="_blank"
        rel="noreferrer"
      >
        {text}
      </Link>
    )
  }
}

export const renderAsInternalLink = (
  getPath: (params: GridRenderCellParams<any, any, any>) => string
) => {
  return function render(params: GridRenderCellParams<any, any, any>) {
    if (!params.value) return null

    return (
      <ReactRouterLink to={getPath(params)} style={{ color: theme.palette['brand-primary'].dark }}>
        {params.value}
      </ReactRouterLink>
    )
  }
}

export const renderCoachAssignmentState = (lastDietitianSubscriptionPropertyPath: string) => {
  return function render({ row, value, formattedValue }: GridRenderCellParams<any, any, any>) {
    if (!value) return null

    const lastDietitianSubscription = get(row, lastDietitianSubscriptionPropertyPath)
    const tooltipTitle =
      value === CoachAssignmentState.Paused && lastDietitianSubscription?.resumesAt
        ? `Resumes ${formatDate({ value: lastDietitianSubscription?.resumesAt })}`
        : formattedValue
    return (
      <Tooltip title={tooltipTitle}>
        <Chip variant="outlined" label={formattedValue} />
      </Tooltip>
    )
  }
}

export const renderAsUserHomePageLink = (
  { row, value }: GridRenderCellParams<any, any, any>,
  getUserId: (row: any) => string = (row) => row.id
) => {
  return (
    <ReactRouterLink
      to={`${LocationPaths.Users}/${getUserId(row)}`}
      style={{ color: theme.palette['brand-primary'].dark }}
    >
      {value}
    </ReactRouterLink>
  )
}

export const renderAccountRequestPayloadParams = ({
  value
}: GridRenderCellParams<any, any, any>) => {
  if (!value) return null

  const renderNestedObject = (obj: { [key: string]: any }): ReactNode[] => {
    const DATE_KEYS = ['billingCycleAnchor', 'resumeDate', 'limitedSupportCancelAt']
    return Object.entries(obj).map(([key, value]) => {
      if (key === '__typename' || !value) return null
      if (typeof value === 'object' && value !== null) {
        return renderNestedObject(value)
      }

      const formattedValue = DATE_KEYS.includes(key)
        ? format(new Date(value), MMM_DD_YYYY)
        : startCase(value)

      return (
        <Typography sx={{ display: 'flex' }} key={key}>
          {startCase(key)}: {formattedValue}
        </Typography>
      )
    })
  }

  return <Stack>{renderNestedObject(value)}</Stack>
}
